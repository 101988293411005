.ag-cell-textcolor-red,
.ag-cell-textcolor-red a {
    color: orangered !important;
}

.ag-cell-backcolor-grey {
    background-color: #f4f4f4;
}

.ag-cell-non-editable {
    outline: none !important;
    border: 1px solid transparent !important;
}

.ag-cell-non-editable:hover  {
    background-color: #f4f4f4 !important;
    border: 1px solid #d1d1d1 !important;
}

.ag-cell-editable:hover  {
    background-color: #E7F3FD !important;
    border: 1px solid #2196F3 !important;
}

.ag-cell-custom {
    width: 100%;
    height: 100%;
    display: flex;
}

.ag-cell-text {
    padding-right: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ag-cell-icon {
    top: 10px;
    right: 5px;
    display: none;
    position: absolute;
}

.ag-cell-custom:hover .ag-cell-icon {
    display: block;
}